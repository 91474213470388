$smallScreen: 768px;
$mediumScreen: 992px;
$largeScreen: 1200px;

#landing{
  margin-top: 20px;
}

.landingText {
  color: rgb(250, 173, 20);
  font-size: 1.2rem;
  margin: 20px 20px;
  text-align: center;

  @media (max-width:$smallScreen) {
    font-size: 2.5rem; /* Adjust the font size for smaller devices */
  }
  @media (max-width:$mediumScreen) {
    font-size: 1.5rem; /* Adjust the font size for smaller devices */
  }
}


div.slide {
  height: 80vh;
}

.verticalRotated {
  margin: 0;
  padding: 0;
  writing-mode: vertical-lr;
  color: rgb(250, 173, 20);

  .rotate {
    transform: rotate(180deg);
  }

}
