@import 'variables';

.topMenu {

  margin: auto;

  @media (min-width: $smallScreen) {
    min-width: 0;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
  }
}


.desktopTopMenu {
  font-size: 1.5rem;
  @media (max-width: $smallScreen) {
    display: none;
  }
}

.mobileTopMenu {
  width: 100%;
  @media (min-width: $smallScreen) {
    display: none;
  }

  .ant-btn-icon-only {
    border-color: $colorBorderSecondary;
    box-shadow: 0 3px 12px 0 $mainBgColor, 0 3px 6px -4px rgb(110, 109, 109), 0 9px 28px 8px rgba(54, 54, 54, 0.21);
  }
}