@import "variables";

body {
  background: radial-gradient(#272727, #1b1b1b);
}

#root {
  height: 100%;
}

.customHeader {
  $defaultHeight: 70px;
  $smallHeight: 80px;
  $mediumHeight: 100px;
  $largeHeight: 110px;

  text-align: center;
  display: flex;
  flex-direction: row;
  height: $defaultHeight;
  line-height: $defaultHeight;

  @media (min-width: $smallScreen) {
    height: $smallHeight;
    line-height: $smallHeight;
  }

  @media (min-width: $mediumScreen) {
    height: $mediumHeight;
    line-height: $mediumHeight;
  }

  @media (min-width: $largeScreen) {
    height: $largeHeight;
    line-height: $largeHeight;
  }

  .ant-menu, .ant-layout-header {
    @media (min-width: $smallScreen) {
      line-height: $smallHeight;
    }

    @media (min-width: $mediumScreen) {
      line-height: $mediumHeight;
    }

    @media (min-width: $largeScreen) {
      line-height: $largeHeight;
    }
  }
}

.logo {
  margin-right: 20px;
  //margin-top: 25px;
  vertical-align: middle;
  height: 100%;

  img {
    vertical-align: middle;
  }
}

.ant-menu {
  align-self: center;
}

.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.myContent {
  padding: 0 10px;
  flex: 1;

  margin: 10px auto;
  width: 100%;
  @media (min-width: $smallScreen) {
    margin: 10px auto;
  }

  @media (min-width: $mediumScreen) {
    margin: 20px auto;
  }

  @media (min-width: $largeScreen) {
    max-width: 1200px;
    margin: 30px auto; /* You can adjust this if needed */
  }
}



.footer {
  border-top-width: 1px;
  border-top-color: rgb(9, 43, 0);
  border-top-style: solid;
  padding-top: 10px;
  text-align: center;
}

.ant-menu-title-content {
  a {
    color: #F6FFED;
  }
}

.horizontal-submenu {
  display: flex;
  flex-direction: row;
}

#info {
  .ant-card-head-title > div > div {
    margin: auto;
  }
}

.bookDetails{
  margin-top: 20px;
}


.ant-float-btn-body {
  background-color: $lightBgColor !important;
  padding: 1px;
}

.ant-float-btn {
  width: 55px;
  height: 55px;
  border-width: 1px;
  border-style: solid;
  border-color: $colorBorderSecondary;
  box-shadow: 0 3px 12px 0 $colorPrimary, 0 3px 6px -4px rgb(110, 109, 109), 0 9px 28px 8px rgba(54, 54, 54, 0.21);
}

.contentWidth {
  margin: 0 auto;
  width: 100%;
}


.customBackTop {
  //margin-bottom: 90px;
  position: absolute;
  right: 0;
  bottom: 20px;
  z-index: 999;

  @media (max-width: $smallScreen) {
    right: 0;
    bottom: -19px;
    width: 40px;
    height: 40px;
    .ant-float-btn-description {
      font-size: 0 !important;
      display: none !important;
    }
  }
}

