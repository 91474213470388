@import "variables";

.desktopDetails {
  @media (max-width: $smallScreen) {
    display: none;
  }
}

.mobileDetails {
  @media (min-width: $smallScreen) {
    display: none;
  }
}

#headlineIcon {
  flex-grow: 1;
  text-align: right;

  .stateIcon {
    font-size: 3rem;
    margin: 10px;
  }
}

#status {
  .stateIcon {
    margin-right: 4px;
    margin-left: 4px;

    @media (min-width: $smallScreen) {
      display: none;
    }

  }

}