@import "variables";

.portfolioContainer {
  margin-bottom: 20px;
  justify-content: center;
  @media (min-width: $smallScreen) {
    display: flex;
  }

  .filterFlex {
    flex-direction: row;
    @media (max-width: $smallScreen) {
      flex-direction: column;
    }
  }
}

.alignAtEnd {
  @media (min-width: $smallScreen) {
    align-items: end;
  }
}

.noWrap{
  text-wrap: nowrap;
}

.portfolio {

  .filterContainer {
    display: flex;
    flex-direction: column;
  }

  #seriesfilter, #statusfilter {
    flex-grow: 1;
    gap: (8 8);

    #statusfilter {
      @media (min-width: $smallScreen) {
        justify-content: end;
        gap: (3 3);
      }
    }

  }

  .filterButton {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  #portfolioDisplay {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .ant-card {
    margin: 10px !important;
    width: 255px;
    padding: 10px;
    //border: #a0d911 solid 2px;
  }

  .ant-btn{
    box-shadow: 0 2px 0 rgba(51, 10, 0, 0.8);
  }

}