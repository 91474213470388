// _variables.scss

// Define breakpoint variables
$smallScreen: 768px;
$mediumScreen: 992px;
$largeScreen: 1200px;

// Define width variables
$smallScreenPadding: 20px;
$mediumScreenPadding: 30px;
$largeScreenPadding: 50px;
$maxWidth: 1200px;

// colors
$colorTextBase: #cccccc;
$colorPrimary: #faad14;
$mainBgColor: #000000;
$headerBg: rgb(51,10,0);
$colorBorderSecondary: rgb(91, 89, 89);
$lightBgColor: rgb(34, 34, 34);

$fontSize: 16,
