@import "../../theme/_variables.scss";

.paper {
    /*background-color: #fff; !* Set background color *!*/
    padding: 10px; /* Add padding for content */
    border-radius: 4px; /* Add border radius for rounded corners */
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); /* Add box shadow for depth effect */

    fieldset {
        border-color: $colorBorderSecondary;
        display: block;
        margin-inline-start: 2px;
        margin-inline-end: 2px;
        padding-block-start: 0.35em;
        padding-inline-start: 0.75em;
        padding-inline-end: 0.75em;
        padding-block-end: 0.625em;
        min-inline-size: min-content;
        border-width: 2px;
        border-style: groove;
        border-image: initial;
        box-shadow: 0 2px 4px rgba(178, 177, 177, 0.61);
        background-color: $lightBgColor;

        label {
            color: $colorBorderSecondary;
            margin-left: 5px;
            margin-right: 5px;
            max-width: 1000px;
            transition: max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms;
        }
    }
}
